<template>
  <div class="container">
    <div class="wrap">
      <div class="wrap-box">
        <img src="../assets/img/logo.png" alt="" />
        <div class="btn btn-default" @click="login" v-if="!isAuth">
          科技云通行证登录
        </div>
        <p v-if="isAuth  && Code ==403">抱歉，您没有访问权限</p>
        <p v-if="isAuth && Code ==410">抱歉，您当前使用的IP地址{{IP}}不能访问平台</p>
        <div class="logout" @click="logout" v-if="isAuth">返回登录</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Footer from "../components/footer";
import { useRouter } from "vue-router";
import { inject, onMounted, ref, onBeforeUnmount } from "vue";
import qs from "qs";
import bus from "@/utils/bus";
let router = useRouter();
let axios = inject("axios");

let code = ref("");
let IP = ref('');
const login = () => {
  // let url = `/dev/login`;
  // // 蓝晨阳  刘庆东
  // let params = {
  //   name: "蓝晨阳",
  // };
  // axios.get(url, { params }).then((res) => {
  //   if (res.status == 200) {
  //     let user = res.data;
  //     localStorage.setItem("User", JSON.stringify(user));
  //     isAuth.value = false;
  //     router.push({ name: "movement" });
  //   } else {
  //     isAuth.value = true;
  //   }
  // });

  let url = process.env.VUE_APP_URL + `/login`;
  window.location.href = url;
};

let isAuth = ref(false);
let Code = ref(200);//状态码200正常有权限访问 、403用户不在用户列表无权限 、410用户ip被封
//获取用户信息
const getUserInfo = (code) => {
  let url = `/callback?code=${code}`;
  axios
    .get(url)
    .then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        let user = res.data;
        localStorage.setItem("User", JSON.stringify(user));
        isAuth.value = false;
        router.push({ name: "movement" });
        Code.value = 200;
      } else if (res.data.code == 403) {
        Code.value = 403;
        isAuth.value = true;
      } else if (res.data.code == 410) {
        Code.value = 410;
        isAuth.value = true;
        IP.value = res.data.ip;
      }

    })
    .catch((error) => {
      isAuth.value = true;
    });
};

//退出登录
const logout = () => {
  localStorage.clear();
  isAuth.value = false;
  window.location.href =
    "https://passport.escience.cn/logout?WebServerURL=http://dc.casdc.cn";
};
onMounted(() => {
  let url = window.location.href;
  const queryString = url.split("?")[1];
  const queryObject = qs.parse(queryString);
  // console.log(queryObject);
  if (queryObject.code) {
    code.value = queryObject.code;
    getUserInfo(code.value);
  }
  //410状态用户无权限被封禁
  bus.on("noPermission", () => {
    isAuth.value = true;
  });
});
onBeforeUnmount(() => {
  bus.all.delete("noPermission");
});
</script>

<style lang="scss" scoped>
.container {
  background: url(../assets/img/banner.png) no-repeat;
  background-size: cover;
  height: 100vh;
  .wrap {
    height: calc(100% - 30px);
    text-align: center;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.wrap-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 360px;
    height: 120px;
    margin-bottom: 100px;
  }

  > p {
    color: #555555;
    font-family: PingFangSC-Regular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    margin-top: 50px;
  }
}
.btn {
  border-radius: 6px;
  width: 200px;
  height: 50px;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  color: #fff;
}
.logout {
  margin-top: 50px;
  font-size: 16px;
  color: #3d55a8;
  text-decoration: underline;
  cursor: pointer;
}
</style>
